<!-- <div *ngIf="parent.authService.globalWarningMessage && parent.isClient"
	class="masterview-header warning-message d-flex justify-content-center align-items-center">
	<span>{{parent.authService.globalWarningMessage}}</span>
</div> -->
<header class="masterview-header">
	<div class="header-wrapper d-flex">
		<div class="header-group left d-flex flex-grow-1">
			<div *ngIf="parent.showSideBar" class="header-group-item ml-2">
				<div class="d-flex h-100 align-items-center">
					<a class="sidebar-collapse btn btn-large btn-floating waves-effect waves-light" (click)="parent.toggleSidebar()">
						<i class="mdi-navigation-menu"></i>
					</a>
				</div>
			</div>
			<div class="header-group-item d-none d-sm-block">
				<div class="d-flex h-100 align-items-center">
					<a href="/" class="d-block">
						<img class="logo" [src]="parent.logoSrc" [alt]="parent.logoAlt"
							onerror="this.style['line-height']='inherit'">
					</a>
				</div>
			</div>
			<!-- client only -->
			<div *ngIf="parent.isClient && parent.hasGlobalMessage()" class="header-group-item ml-2 d-flex flex-grow-1 justify-content-center align-items-center">
				<div *ngIf="parent.hasGlobalImportantMessage()" class="warning-message">
					<div class="card red lighten-1 cursor-pointer" (click)="parent.openLink()">
						<div class="card-content white-text mt-1 mb-1 ml-2 mr-2">
							MODIFICĂRI FISCALE DIN 01.01.2023 - CITEȘTE AICI MAI MULT!
						</div>
					</div>
				</div>
				<div *ngIf="!parent.hasGlobalImportantMessage() && parent.hasGlobalWarningMessage()" class="warning-message">
					<span>{{parent.authService.globalWarningMessage}}</span>
				</div>
			</div>
		</div>
		<!-- company section: fiscalNumber - companyName -->
		<div class="header-group right hide-on-med-and-down flex-shrink-0">
			<div *ngIf="parent.showSideBar && parent.authService.isLoggedIn && !parent.isSubcontractor" class="header-group-action ml-2">
				<div class="d-flex h-100 align-items-center">
					{{parent.authService.user.selectedCompany.fiscalNumber}}
				</div>
			</div>
			<div *ngIf="parent.showSideBar && parent.authService.isLoggedIn && !parent.isSubcontractor" class="header-group-action divider-line">
				<div class="d-flex h-100 align-items-center">&nbsp;-&nbsp;</div>
			</div>
			<div *ngIf="parent.showSideBar && parent.authService.isLoggedIn && !parent.isSubcontractor" class="header-group-action">
				<div class="d-flex h-100 align-items-center">
					<!-- AM. 05.06.2024. task 9177: [hover]="!showFilter" -->
					<dropdown-button #clientsMenu id="clientsMenu" 
						class="waves-effect waves-block waves-light header-notifications h-100 pl-1 pr-3" list-class="header-clients"
						(onshow)="onShowCompaniesMenu()" (onhide)="onHideCompaniesMenu()">
						{{parent.authService.user.selectedCompany.companyName}}
						<i class="mdi-navigation-arrow-drop-down"></i>
						<dropdown-button-item *ngIf="showFilter">
							<input #filterInput type="text" class="my-2 w-100" [(ngModel)]="companiesFilter" (click)="onInputClick($event)"
								(touchstart)="onInputClick($event)" (keydown)="onInputKeyDown($event)" autofocus />
						</dropdown-button-item>
						<dropdown-button-item *ngFor="let company of companies" class="px-4" (itemClick)="selectCompany(company.externalId)">
							<!-- AM. 04.05.2025. task 9054: show fiscalNumber also for HRExpert (removed: !parent.isHRExpert) -->
							{{ company.fiscalNumber }} - {{company.companyName}}
						</dropdown-button-item>
					</dropdown-button>
				</div>
			</div>

			<!-- languages section -->
			<div *ngIf="parent.showLanguages && !parent.isSubcontractor" class="header-group-action">
				<div class="d-flex h-100 align-items-center">
					<dropdown-button id="translationMenu" [hover]="true" 
						class="waves-effect waves-block waves-light header-languages" list-class="header-languages">
						<img src="/images/flag-icons/United-States.png" class="selected" alt="USA" />
						<dropdown-button-item>
							<img src="/images/flag-icons/United-States.png" alt="English" />
							<span class="language-select">English</span>
						</dropdown-button-item>
						<dropdown-button-item>
							<img src="/images/flag-icons/France.png" alt="French" />
							<span class="language-select">French</span>
						</dropdown-button-item>
						<dropdown-button-item>
							<img src="/images/flag-icons/Germany.png" alt="German" />
							<span class="language-select">German</span>
						</dropdown-button-item>
					</dropdown-button>
				</div>
			</div>

			<!-- user zone/role section -->
			<div *ngIf="parent.authService.isLoggedIn" class="header-group-action">
				<div *ngIf="parent.authService.displayZones.length != 1 || parent.authService.selectedZoneCode != parent.PredefinedUserZone.Administrator" 
					class="user-role-container d-flex h-100 align-items-center">
					<dropdown-button #userRole id="userRole" *ngIf="parent.authService.displayZones.length > 1"
						class="waves-effect waves-block waves-light" list-class="role-dropdown">
						<span>{{ parent.authService.selectedZoneDisplayName }}</span>
						<i class="mdi-navigation-arrow-drop-down"></i>
						<dropdown-button-item *ngIf="parent.authService.isInZone(parent.PredefinedUserZone.Administrator)" (itemClick)="parent.switchZone(parent.PredefinedUserZone.Administrator)">
							<span>{{ parent.authService.getZoneDisplayName(parent.PredefinedUserZone.Administrator) }}</span>
						</dropdown-button-item>
						<dropdown-button-item *ngIf="parent.authService.isInZone(parent.PredefinedUserZone.Expert)" (itemClick)="parent.switchZone(parent.PredefinedUserZone.Expert)">
							<span>{{ parent.authService.getZoneDisplayName(parent.PredefinedUserZone.Expert) }}</span>
						</dropdown-button-item>
						<dropdown-button-item *ngIf="parent.authService.isInZone(parent.PredefinedUserZone.HrExpert)" (itemClick)="parent.switchZone(parent.PredefinedUserZone.HrExpert)">
							<span>{{ parent.authService.getZoneDisplayName(parent.PredefinedUserZone.HrExpert) }}</span>
						</dropdown-button-item>
						<dropdown-button-item *ngIf="parent.authService.isInZone(parent.PredefinedUserZone.Subcontractor)" (itemClick)="parent.switchZone(parent.PredefinedUserZone.Subcontractor)">
							<span>{{parent.authService.getZoneDisplayName(parent.PredefinedUserZone.Subcontractor) }}</span>
						</dropdown-button-item>
					</dropdown-button>
					<span *ngIf="parent.authService.displayZones.length == 1" class="user-role">{{ parent.authService.selectedZoneDisplayName }}</span>
				</div>
			</div>
			
			<!-- user name section -->
			<div #userProfileAnchor *ngIf="parent.authService.isLoggedIn" class="header-group-action initialLettersContainer cursor-pointer" (click)="showUserProfile()">
				<div class="d-flex h-100 align-items-center initialLetters">
					{{ parent.authService.userNameInitialLetters }}
				</div>
				<kendo-popup #userProfilePopup *ngIf="isUserProfileVisible" [anchor]="{ nativeElement: userProfileAnchor }" [popupAlign]="userProfilePopupAlign" [anchorAlign]="userProfileAnchorAlign">
				  <div class="userProfileContent">
						<!-- <div class="text-center initialLettersContent">{{ parent.authService.userNameInitialLetters }}</div> -->
						<div class="pt-2 userName">{{ parent.authService.userName }}</div>
						<div class="pb-4">{{ parent.authService.userEmail }}</div>
						<div class="d-flex align-items-center cursor-pointer userProfileAction" (click)="gotoProfile()">
							<i class="mdi-action-face-unlock"></i>Profil
						</div>
						<div class="d-flex align-items-center cursor-pointer userProfileAction" (click)="gotoContact()">
							<i class="mdi-action-help"></i>
							Ajutor
						</div>
						<div class="d-flex align-items-center cursor-pointer userProfileAction" (click)="parent.authService.logout()">
							<i class="mdi-hardware-keyboard-tab"></i>
							Ieșire
						</div>
				  </div>
				</kendo-popup>
			</div>

			<!-- actions section -->
			<!-- [removed] goToContact button -->
			<div *ngIf="false && !parent.isSubcontractor" class="header-group-action d-none d-sm-block">
				<div class="d-flex h-100 align-items-center">
					<a href="javascript:void(0);" (click)="parent.gotoContact()" class="waves-effect waves-block waves-light">
						<i class="mdi-action-help"></i>
					</a>
				</div>
			</div>

			<!-- help button -->
			<div *ngIf="!parent.isHRExpert && !parent.isSubcontractor" class="header-group-action d-none d-sm-block">
				<div class="d-flex h-100">
					<dropdown-button 
						class="h-100 d-flex align-items-center waves-effect waves-block waves-light header-help"
						list-class="header-help">
						<i class="mdi-action-help"></i>
						<div role="header" class="grey-text text-darken-3">
							<h5 class="p-2 m-0">Ajutor</h5>
						</div>
						<!-- showVideos -->
						<dropdown-button-item *ngIf="helpVideosAreVisible" class="py-2" (itemClick)="onShowVideosValueChange($event)">
							<div style="display: table">
								<div class="col-24 d-flex flex-column">
									<a class="p-0 cyan-text">Ajutor la început</a>
									<span class="grey-text text-darken-3">Poți vizualiza filmulețele puse la dispoziție</span>
								</div>
								<div class="col-24 px-5" style="display: table-cell; width: 50px">
									<kendo-switch [style.transform]="'scale(0.7, 0.7)'" [(ngModel)]="this.parent.authService.user.showHelp" (valueChange)="onShowVideosValueChange($event)"></kendo-switch>
								</div>
							</div>
						</dropdown-button-item>
						<!-- helpMenu -->
						<dropdown-button-item *ngFor="let hmItem of helpMenuItems()" class="py-2">
							<div class="col-24 d-flex flex-column">
								<a class="p-0 cyan-text" target="_blank" href="{{hmItem.link}}">{{hmItem.title}}</a>
								<span class="grey-text text-darken-3">{{hmItem.description}}</span>
							</div>
						</dropdown-button-item>
						<!-- userGuide -->
						<dropdown-button-item class="py-2">
							<div class="col-24 d-flex flex-column">
								<a class="p-0 cyan-text" target="_blank" href="https://app.keez.ro/help/client/index.html" rel="noopener noreferrer">Ghidul de utilizare</a>
								<span class="grey-text text-darken-3">Poți consulta ghidul de utilizare</span>
							</div>
						</dropdown-button-item>
						<!-- userGuide for expert -->
						<dropdown-button-item *ngIf="parent.isAccExpert" class="py-2">
							<div class="col-24 d-flex flex-column">
								<a class="p-0 cyan-text" target="_blank" href="https://app.keez.ro/help/expert/index.html" rel="noopener noreferrer">Manualul expertului Keez</a>
								<span class="grey-text text-darken-3">Instrucțiuni detaliate pentru Experti</span>
							</div>
						</dropdown-button-item>
						<!-- [removed] ask Keez -->
						<dropdown-button-item *ngIf="false" class="py-2">
							<div class="col-24 d-flex flex-column">
								<a class="p-0 cyan-text" target="_blank" href="mailto:intreaba@keez.ro">Întreabă-ne la intreaba@keez.ro</a>
								<span class="grey-text text-darken-3">Trimite-ne o întrebare prin email. Se transformă automat în tichet</span>
							</div>
						</dropdown-button-item>
						<!-- [removed] freshDesk -->
						<dropdown-button-item *ngIf="false" class="py-2" (itemClick)="freshDesk()">
							<div class="col-24 d-flex flex-column">
								<a class="p-0 cyan-text">Pune un tichet</a>
								<span class="grey-text text-darken-3">Pune un tichet în sistemul de urmărire tichete keez.</span>
							</div>
						</dropdown-button-item>
					</dropdown-button>
				</div>
			</div>

			<!-- rating button -->
			<!-- AM. 25.05.2023: replaced by Wootric -->
			<!-- <div *ngIf="!parent.isHRExpert && !parent.isSubcontractor" class="header-group-action d-none d-sm-block">
				<div class="d-flex h-100 align-items-center">
					<a href="javascript:void(0);" (click)="parent.openRatingDialog('web-option')" class="waves-effect waves-block waves-light">
						<i class="mdi-action-stars"></i>
					</a>
				</div>
			</div> -->

			<!-- [removed] notification button -->
			<div *ngIf="false && parent.showSideBar && parent.authService.isLoggedIn && parent.notifications.length && !parent.isSubcontractor" class="header-group-action">
				<div class="d-flex h-100 align-items-center">
					<dropdown-button id="notificationMenu" [hover]="true"
						class="waves-effect waves-block waves-light header-notifications h-100" list-class="header-notifications"
						(click)="parent.gotoNotification(null)">
						<i class="mdi-social-notifications">
							<small class="notification-badge">{{parent.notifications.length}}</small>
						</i>
						<li list-top>
							<div class="h5">NOTIFICĂRI
								<span class="badge badge-secondary">{{parent.notifications.length}}</span>
							</div>
						</li>
						<li list-top class="divider"></li>
						<dropdown-button-item *ngFor="let notification of parent.notifications" (itemClick)="parent.gotoNotification(notification.externalId)">
							{{notification.subject}}
							<div class="w-100 px-0 text-right grey-text text-darken-4">
								<time list-item-bottom class="media-meta" [dateTime]="notification.created">{{notification.createdText}}</time>
							</div>
						</dropdown-button-item>
					</dropdown-button>
				</div>
			</div>

			<!-- [removed] fullScreen button -->
			<div *ngIf="false && !parent.isSubcontractor" class="header-group-action d-none d-sm-block">
				<div class="d-flex h-100 align-items-center">
					<a href="javascript:void(0);" (click)="parent.toggleFullScreen()" class="waves-effect waves-block waves-light toggle-fullscreen">
						<i class="mdi-action-settings-overscan"></i>
					</a>
				</div>
			</div>
		</div>
	</div>
</header>